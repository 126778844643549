<template>
    <a-modal :title="record.centerName" v-model="visible" width="1100px">
        <actual ref="actual"></actual>
        <today ref="today"></today>
        <week ref="week"></week>
        <month ref="month"></month>
    </a-modal>
</template>

<script>
import month from './component/month'
import week from './component/week'
import today from './component/today'
import actual from './component/actual'
export default {
    components: { actual , today, week, month},
    data() {
        return {
          visible: false,
          record: {}
        }
    },
    methods: {
      showModal(data) {
        this.visible = true
        this.record = data
        setTimeout(() => {
          this.$refs.actual.queryCenterActual(this.record.centerCode)
          this.$refs.actual.getTypes()
          this.$refs.today.queryCenterTodayAnalyze(this.record.centerCode)
          this.$refs.week.queryCenterWeekAnalyze(this.record.centerCode)
          this.$refs.month.queryThisMonthCollectData(this.record.centerCode)
        }, 200)
      }
    }
}
</script>

<style lang="scss">
  .p-title{
    font-weight: bolder;
    font-size: larger;
  }
</style>