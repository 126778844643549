<template>
    <div>
        <a-row style="margin-bottom:10px;">
          <a-form layout="inline">
            <a-form-item label="查询小区">
              <a-input v-model="params.centerName" style="width:200px;" allowClear />
            </a-form-item>
            <a-form-item label="查询时间">
              <a-range-picker v-model="params.time" @change="onChangeTime"/>
            </a-form-item>
            <a-form-item >
              <a-button @click="onSearch" type="primary">查询</a-button>
            </a-form-item>
          </a-form>
        </a-row>
        <a-table :data-source="data" :pagination="pagination" :columns="columns" :loading="loading" :scroll="{ y: 650 }">
            <span slot="kitchenWastePercent" slot-scope="text, record">{{record.kitchenWastePercent}}%</span>
            <span slot="otherPercent" slot-scope="text, record">{{record.otherPercent}}%</span>
            <span slot="action" slot-scope="text, record">
                <a-button type="link" @click="viewDetail(record)">查看详情</a-button>
            </span>
        </a-table>
        <detail ref="detail"/>
    </div>
</template>

<script>
import util from '@/utils/util'
import moment from 'moment'
import { queryCenterAnalyzeApi } from '@/api/center'
import detail from '../centerDetail/index'
export default {
    components: { detail },
    data() {
        return{
            loading: false,
            data: [],
            params: {
                page: 1,
                limit: 10,
                startTime: '',
                endTime: '',
                centerName: '',
                time: ''
            },
            types: [],
            pagination: {
                pageSize: 10,
                current: 1,
                total: 0,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total) => {
                return '共' + total + '条'
                },
                pageSizeOptions: ['10', '20', '30', '40'],
                onChange: (current, size) => this.changePage(current, size),
                onShowSizeChange:(current, pageSize)=>this.changePage(current, pageSize),
            },
            columns: [
                {
                    title: '小区编码',
                    dataIndex: 'centerCode',
                    key: 'centerCode'
                },
                {
                    title: '小区名称',
                    dataIndex: 'centerName',
                    key: 'centerName'
                },
                {
                    title: '采集总量(kg)',
                    dataIndex: 'total',
                    key: 'total'
                },
                {
                    title: '厨余垃圾(kg)',
                    dataIndex: 'kitchenWaste',
                    key: 'kitchenWaste'
                },
                {
                    title: '其他垃圾(kg)',
                    dataIndex: 'other',
                    key: 'other'
                },
                {
                    title: '可回收物(kg)',
                    dataIndex: 'recyclable',
                    key: 'recyclable'
                },
                {
                    title: '有害垃圾(kg)',
                    dataIndex: 'harmful',
                    key: 'harmful',
                },
                {
                    title: '厨余分出率',
                    dataIndex: 'kitchenWastePercent',
                    key: 'kitchenWastePercent',
                    scopedSlots: { customRender: 'kitchenWastePercent'}
                },
                {
                    title: '其他垃圾分出率',
                    dataIndex: 'otherPercent',
                    key: 'otherPercent',
                   scopedSlots: { customRender: 'otherPercent'}
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    key: 'action',
                   scopedSlots: { customRender: 'action'}
                },
            ]
        }
    },
    mounted() {
        this.queryCenterAnalyze()
        this.getTypes()
    },
    filters: {
        filterTime(val) {
            return moment(val).format('YYYY-MM-DD HH:mm:ss') === 'Invalid date' ? '' : moment(val).format('YYYY-MM-DD HH:mm:ss')
        }
    },
    methods: {
        viewDetail(record){
            this.$refs.detail.showModal(record)
        },
        onSearch() {
            this.changePage(1, 10)
        },
        getTypes() {
            util.queryCenterGarbageType(this.$route.query.moduleCode).then(res => {
                this.types = res
            })
        },
        handleChange(e) {
            this.params.typeCode = e
            this.changePage(1, 10)
        },
        onChangeTime(e) {
            this.params.startTime = e[0]
            this.params.endTime = e[1]
            this.queryCenterAnalyze()
        },
        queryCenterAnalyze() {
            this.loading = true
            queryCenterAnalyzeApi({...this.params}, this.$route.query.moduleCode).then(res => {
                this.data = res.result.content
                this.data.forEach(item => {
                    item.collectTime = moment(item.collectTime).format('YYYY-MM-DD HH:mm:ss')
                })
                this.pagination.total = res.result.totalElements
                this.loading = false
            })
        },
        changePage(current, size){
            this.pagination.current = current
            this.pagination.pageSize = size
            this.params.page = current
            this.params.limit = size
            this.queryCenterAnalyze()
        },
    }
}
</script>